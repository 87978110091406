import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const ContactSupportLink = 'https://www.bidsandtenders.com/buyers-support/';
const HelpCenterLink = 'https://www.bidsandtenders.com/';
const DefaultErrorHeaderTitle = `Network Issue Detected`;
const DefaultErrorBodyMessage = `<div>We're experiencing trouble connecting to the server. This could be due to a temporary network issue. Please try refreshing your browser or check your internet connection.</div>`;
const DefaultErrorSupport = `<div class="mt-3 font-14"><h4>Need Further Assistance?</h4><a href="${ContactSupportLink}" target="_blank">Contact support</a> or visit our <a href="${HelpCenterLink}" target="_blank">help center</a> for more information.</div>`;

const UnauthorizedErrorHeaderTitle = `Unauthorized`;
const UnauthorizedErrorBodyMessage = `Permission Denied: You do not have access to this request.`;

const ServiceErrorHeaderTitle = `Server error detected`;
const ServiceErrorBodyMessage = `Our service is currently not reachable.`;

@Injectable({
  providedIn: 'root',
})
export class CustomToastrService {
  constructor(private toastr: ToastrService) {}

  error(title: string, messaage: string, errorCode: any = null) {
    const ErrorCodeMessage = `<div class="mt-3">Error Code: ${errorCode}</div>`;
    messaage = errorCode
      ? messaage + DefaultErrorSupport + ErrorCodeMessage
      : messaage + DefaultErrorSupport;
    this.toastr.error(messaage, title, {
      enableHtml: true,
    });
  }

  defaultError(errorCode: any = null) {
    const ErrorCodeMessage = `<div class="mt-3">Error Code: ${errorCode}</div>`;
    const messaage = errorCode
      ? DefaultErrorBodyMessage + DefaultErrorSupport + ErrorCodeMessage
      : DefaultErrorBodyMessage + DefaultErrorSupport;
    this.toastr.error(messaage, DefaultErrorHeaderTitle, {
      enableHtml: true,
    });
  }
  unAuthorizedError(errorCode: any = null) {
    const ErrorCodeMessage = `<div class="mt-3">Error Code: ${errorCode}</div>`;
    const messaage = errorCode
      ? UnauthorizedErrorBodyMessage + DefaultErrorSupport + ErrorCodeMessage
      : UnauthorizedErrorBodyMessage + DefaultErrorSupport;
    this.toastr.error(messaage, UnauthorizedErrorHeaderTitle, {
      enableHtml: true,
    });
  }
  serviceNotAvailable(errorCode: any = null) {
    const ErrorCodeMessage = `<div class="mt-3">Error Code: ${errorCode}</div>`;
    const messaage = errorCode
      ? ServiceErrorBodyMessage + DefaultErrorSupport + ErrorCodeMessage
      : ServiceErrorBodyMessage + DefaultErrorSupport;
    this.toastr.error(messaage, ServiceErrorHeaderTitle, {
      enableHtml: true,
    });
  }
  success(title: string, messaage: string) {
    this.toastr.success(messaage, title);
  }
  warning(title: string, messaage: string) {
    this.toastr.warning(messaage, title);
  }
  clear() {
    this.toastr.clear();
  }
}
