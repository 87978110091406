import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  menuItems = [
    { name: 'Build Evaluation', url: 'build-evaluation' },
    { name: 'Evaluation Team', url: 'evaluation-team' },
    { name: 'Evaluation Review', url: 'review-meetings' },
    { name: 'Supplier Status', url: 'supplier-status' },
  ];
  isExpanded = true;

  constructor(private render: Renderer2) {}

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
    const mainContent = document.getElementById('main-content');
    if (this.isExpanded) {
      this.render.addClass(mainContent, 'is-expanded');
    } else {
      this.render.removeClass(mainContent, 'is-expanded');
    }
  }
}
