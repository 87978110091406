import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StatusComponent } from './components/status/status.component';
import { IconComponent } from './components/icon/icon.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LoaderComponent } from './components/loader/loader.component';
import { LandingPageErrorComponent } from './components/landing-page-error/landing-page-error.component';
import {
  NgxDaterangepickerBootstrapModule,
  NgxDaterangepickerLocaleService,
} from 'ngx-daterangepicker-bootstrap';

import { ModalComponent } from './components/modal/modal.component';

import { ManageEvaluatorsComponent } from '../modules/components/manage-evaluators/manage-evaluators.component';

@NgModule({
  declarations: [
    SidebarComponent,
    StatusComponent,
    IconComponent,
    PageLoaderComponent,
    ConfirmDialogComponent,
    TextEditorComponent,
    LoaderComponent,
    LandingPageErrorComponent,

    ModalComponent,

    ManageEvaluatorsComponent,
  ],
  exports: [
    SidebarComponent,
    StatusComponent,
    IconComponent,
    PageLoaderComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    LandingPageErrorComponent,
    RouterModule,
    NgxDaterangepickerBootstrapModule,
    TextEditorComponent,

    ModalComponent,

    ManageEvaluatorsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CKEditorModule,
    NgxDaterangepickerBootstrapModule.forRoot(),
    NgPipesModule,
  ],
  providers: [NgxDaterangepickerLocaleService],
})
export class CoreModule {}
