import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serviceUrl } from '../../../shared/constants/api-urls';

@Injectable({
  providedIn: 'root',
})
export class EvaluationTeamService {
  constructor(private http: HttpClient) {}

  getTeamMembersList(): Observable<any> {
    return this.http.get(serviceUrl.getTeamMembersList);
  }
  getEvaluatorsList(evaluationId: string): Observable<any> {
    return this.http.get(
      `${serviceUrl.getEvaluatorsList}?evaluationId=${evaluationId}`
    );
  }
  saveEvaluatorsList(data: any): Observable<any> {
    return this.http.put(serviceUrl.saveEvaluatorsList, data);
  }
  getEvaluationStages(id: string, data: any): Observable<any> {
    return this.http.post(
      `${serviceUrl.evaluation}/${id}/assign/evaluators/stage`,
      data
    );
  }
  getLibraryContent(evaluationId: string, data: any): Observable<any> {
    return this.http.get(
      `${serviceUrl.getLibraryContent}/${evaluationId}/librarycontent?groupType=${data}`,
      data
    );
  }

  getCoiContent(evaluationId: string, teamMemberId: string): Observable<any> {
    return this.http.get(
      `${serviceUrl.getCoiContent}/${evaluationId}/evaluator/${teamMemberId}/acknowldgementtype/3/evaluatoracknowldgement`
    );
  }

  submitDismissal(
    payload: any,
    evaluationId: string,
    teamMemberId: string
  ): Observable<any> {
    return this.http.put(
      `${serviceUrl.getCoiContent}/${evaluationId}/evaluator/${teamMemberId}/acknowldgementtype/3/dismissalcomment`,
      payload
    );
  }

  saveEvaluatorsStages(data: any): Observable<any> {
    return this.http.put(
      `${serviceUrl.saveEvaluationStages}/assign/evaluators/stage`,
      data
    );
  }
  updateStage(payload: any): Observable<any> {
    return this.http.put(`${serviceUrl.evaluation}/stage`, payload);
  }

  deleteEvaluationStage(payload: any): Observable<any> {
    return this.http.put(serviceUrl.deleteEvaluationStage, payload);
  }
}
