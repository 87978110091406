import { environment } from '../../../environments/environment';
const baseUrl = environment.BASE_URL;
const proPortalBaseUrl = environment.PRO_PORTAL_BASE_URL;
const apiVersion = 'api/v1';
const nodeId = '4e1140f8-6cd6-41d3-96da-d4bf8b6d58d0';
const projectId = 'a8156cef-0ae6-4202-be60-f748d185667b';

const proPortalnodeIdAlt = '4e1140f8-6cd6-41d3-96da-d4bf8b6d58d0';
const proPortalprojectId = 'a8156cef-0ae6-4202-be60-f748d185667b';
const proPortalcustomerId = '3b25a083-5dcc-4fd6-8dad-f3cb66711122';

const API_BASE_URL = `${baseUrl}/${apiVersion}/node/${nodeId}/project/${projectId}`;
const PRO_PORTAL_API_BASE_URL = `${baseUrl}/${apiVersion}/node/${proPortalnodeIdAlt}/project/${proPortalprojectId}/customer/${proPortalcustomerId}`;

export const serviceUrl: any = {
  proPortalBaseURl: `${proPortalBaseUrl}/#/team/addteammember`,
  apiBaseURL: API_BASE_URL,
  proPortalApiBaseURL: PRO_PORTAL_API_BASE_URL,
  evaluationExists: `${API_BASE_URL}/evaluation/exists`,
  evaluation: `${API_BASE_URL}/evaluation`,
  getMasterDataForEvaluation: `${PRO_PORTAL_API_BASE_URL}/evaluation/master`,
  getStages: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
  deleteStage: `${API_BASE_URL}/evaluation/stage`,
  deleteSection: `${API_BASE_URL}/evaluation/section`,
  deleteCriteria: `${API_BASE_URL}/evaluation/criteria`,
  deleteEvaluationStage: `${API_BASE_URL}/assign/evaluators/stage`,
  proposalResponse: `${PRO_PORTAL_API_BASE_URL}/proposalresponse`,
  saveBuildFlow: `${API_BASE_URL}/evaluation/autosave`,
  getTeamMembersList: `${PRO_PORTAL_API_BASE_URL}/teammembers`,
  getEvaluatorsList: `${PRO_PORTAL_API_BASE_URL}/evaluators`,
  saveEvaluatorsList: `${PRO_PORTAL_API_BASE_URL}/evaluators`,
  saveEvaluationStages: `${API_BASE_URL}`,

  getAssignEvaluatorsList: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
  getLibraryContent: `${PRO_PORTAL_API_BASE_URL}/evaluation`,

  getEvaluatorsListByCriteria: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
  saveEvaluatorsListByCriteria: `${API_BASE_URL}/assign/evaluators`,
  supplierStatusList: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
  supplierqualifystatus: `${PRO_PORTAL_API_BASE_URL}/supplierqualifystatus`,
  unsealpricing: `${PRO_PORTAL_API_BASE_URL}/unsealpricing`,
  getCoiContent: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
};
