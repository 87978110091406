import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {
  @Input() showModal = false;
  @Input() headerClass: string | null = null;
  @Input() modalData: any = {};
  @Input() modalSize = 'lg';
  @Output() closeModalEvent = new EventEmitter<void>();
  closeModal(): void {
    this.closeModalEvent.emit();
  }
}
